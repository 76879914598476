import React from "react";

import Box from "@mui/material/Box";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ActionCreators from "../../redux/actionCreators.js";

import ReactDraggableList from "react-draggable-list";

import PlaylistItemTemplate from "./PlaylistItemTemplate.js";

export default function Playlist(props) {
  const sx = props.sx;

  const { playlist, currentTrack } = useSelector(
    /** @type {import("../../redux/types.js").useSelectCb} */
    ({ playlist, currentTrack }) => ({
      playlist,
      currentTrack,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const onReorder = (newList) =>
    dispatch(ActionCreators.updatePlaylist(newList));
  const onTrackSelect = (index) => {
    dispatch(ActionCreators.changeTrack(index));
    dispatch(ActionCreators.play());
  };

  const draggablelistContainerRef = React.createRef();
  return (
    <Box
      ref={draggablelistContainerRef}
      sx={{
        margin: (theme) => theme.spacing(),
        width: "10vw",
        height: "10vh",
        ...sx,
      }}
    >
      <ReactDraggableList
        list={playlist}
        itemKey="ID"
        template={PlaylistItemTemplate}
        onMoveEnd={onReorder}
        container={() => draggablelistContainerRef.current}
        commonProps={{
          listOfID: playlist.map((element) => element.ID),
          currentTrackID: playlist[currentTrack].ID,
          onTrackSelect: onTrackSelect,
        }}
      />
    </Box>
  );
}
