import React from "react";
import "./App.css";
import Visuals from "./module/components/Visuals";
import IOS from "./module/components/IOS";
import Player, { TrackModel, PlayerInterface } from "./module/index.js";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { createTheme } from '@mui/material/styles';
import TrackList from './module/components/TrackList';

function App() {
  const [
    {
      width,
      position,
      bottom,
      boxShadow,
      borderRadiusTL,
      borderRadiusTR,
      borderRadiusBL,
      borderRadiusBR,
    },
  ] = React.useState({
    width: "100vw",
    position: "fixed",
    bottom: 0,
    boxShadow: 8,
    borderRadiusTL: 1,
    borderRadiusTR: 1,
    borderRadiusBL: 0,
    borderRadiusBR: 0,
  });

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: '#fffff',
      },
      background: {
        paper: "#fffff",
      },
    },
  });

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const iosCheck = iOS();
  const visuals = iosCheck ? IOS : Visuals
  const randomNum = Math.floor(Math.random() * visuals.length)

  console.log()

  return (
    <div className='main'>
      <div className="overlay"></div>
      <video src={visuals[randomNum].href} autoPlay loop muted playsInline />
      <div className="content">
        <h1>SYNOPSIS</h1>
        <h4>Alessio Carcavallo</h4>
        <ThemeProvider theme={theme}>
          <Player
            sx={{
              width: width,
              position: position,
              bottom: bottom,
              boxShadow: boxShadow,
              borderRadius: `${borderRadiusTL}px ${borderRadiusTR}px ${borderRadiusBL}px ${borderRadiusBR}px`,
            }}
            disableDrawer={true}
          />
        </ThemeProvider >
      </div>
    </div>
  );
}

PlayerInterface.play([
  new TrackModel(
    "1",
    "./avatar.jpg",
    "synopsis dimension",
    "",
    "https://asacollective.ch/synopsis%20dimension%20sample.wav"
  ),
])
/*
TrackList.map(track => {
  return (
    window.setTimeout(
      () =>
        PlayerInterface.playNext([
          new TrackModel(
            track.id,
            track.thumbnail,
            track.trackName,
            track.trackArtist,
            track.href
          ),
        ]),
    )
  );
});
*/


export default App;
